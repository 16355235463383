<template>
    <footer>
        <div class="wrapper">
<!--            <div class="socials">-->
<!--                <a href="https://github.com/chazn3x" target="_blank" class="icon">-->
<!--                    <i class="fab fa-github"></i>-->
<!--                </a>-->
<!--                <a href="https://www.instagram.com/chaznex" target="_blank" class="icon">-->
<!--                    <i class="fab fa-instagram"></i>-->
<!--                </a>-->
<!--                <a href="https://www.youtube.com/channel/UCWeiaQCKJZ4iblijC-KL31A" target="_blank" class="icon">-->
<!--                    <i class="fab fa-youtube"></i>-->
<!--                </a>-->
<!--            </div>-->
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss" scoped>
footer {
    width: 100%;
    padding: 3rem 1rem 1rem;
    .wrapper {
        a {
            transition: opacity .2s;
            cursor: default;
        }
        a:hover {
            opacity: .8;
            text-decoration: underline;
        }
        .socials {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            .icon {
                margin: 1rem;
                font-size: 1.5rem;
                color: white;
            }
        }
        .links {
            display: grid;
            grid-template-columns: repeat(auto-fill, 170px);
            column-gap: 20px;
            row-gap: 20px;
            h5 {
                color: white;
                font-size: 1rem;
            }
            ul {
                li {
                    padding: .5rem 0 0;
                    span, a {
                        color: rgba(255,255,255,0.5);
                        font-size: .9rem;
                        font-weight: 300;
                    }
                }
            }
        }
        .credits {
            padding-top: 2rem;
            color: rgba(255,255,255,0.4);
            font-weight: 200;
            font-size: .8rem;
            a {
                color: red;
            }
        }
    }
    @media screen and (min-width: 768px) {
        .wrapper {
            width: 768px;
            margin: 0 auto;
            a {
                cursor: pointer;
            }
        }
    }
}
</style>