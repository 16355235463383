import axios from "axios";
import {setupCache} from 'axios-cache-adapter'

const showingUrl = 'https://showing.m-dev.pl/api/';

// Create `axios-cache-adapter` instance
const cache = setupCache({
    maxAge: 1440 * 60 * 1000
})

// Create `axios` instance passing the newly created `cache.adapter`
export const api = axios.create({
    adapter: cache.adapter
})


export async function getTitleDetails(tmdb_id) {

    return await api.get(showingUrl + 'show/' + tmdb_id);

}
